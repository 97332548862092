<template>
    <v-container fluid :class="['pa-0', { 'fill-height': !leistungserbringer }]">

        <!-- Ausrichtung abh�ngig ob interner oder externer Bereich -->
        <v-row :class="alignment">
            <v-col cols="12" sm="12" md="10" lg="8">
               
                <v-form id="kontaktForm" ref="form" lazy-validation>
                    <v-card :borderless="!!leistungserbringer">
                        <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                            <span :class="!leistungserbringer ? 'primary--text' : ''">
                                Kontakt
                            </span>
                        </v-card-title>
                        <v-card-text class="text-body-1 text--primary">
                            <v-container fluid class="pa-0">
                                <v-row v-if="!leistungserbringer" dense>
                                    <v-col cols="12" md="6">
                                        <v-text-field label="IK" v-model="kontakt.vIK"
                                                      autocomplete="off" :rules="IKRules"
                                                      dense required clearable />

                                    </v-col>
                                </v-row>
                                <v-row v-if="!leistungserbringer" dense>
                                    <v-col cols="12" md="6">
                                        <v-text-field label="E-Mail" v-model="kontakt.vEmail"
                                                      autocomplete="email" :rules="emailRules"
                                                      dense required clearable />

                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col cols="12" md="6">
                                        <v-text-field label="Telefonnummer" v-model="kontakt.vTel"
                                                      dense clearable />

                                    </v-col>
                                </v-row>
                                <v-row v-if="leistungserbringer" dense>
                                    <v-col cols="12" md="6">
                                        <v-select :label="kontakt.vSelectedKontaktArt == null ? decoder('Bitte Kontaktart ausw&#xE4;hlen') : 'Kontaktart'"
                                                  v-model="kontakt.vSelectedKontaktArt" :items="vKontaktArt" item-text="text" item-value="value"
                                                  :menu-props="{ maxHeight: '400' }" class="custom-dense" />

                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col cols="12">
                                        <v-text-field label="Betreff" v-model="kontakt.vSubject"
                                                      autocomplete="off" :rules="subjectRules"
                                                      dense required clearable />
                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col cols="12">
                                        <v-textarea label="Ihre Nachricht" v-model="kontakt.vMessage"
                                                    autocomplete="off" :rules="feedbackRules" rows="6"
                                                    required filled />

                                    </v-col>
                                </v-row>
                                <v-row v-if="errorMessage" dense>
                                    <v-col cols="auto">
                                        <v-alert border="left" type="error" class="mb-0">
                                            {{ errorMessage }}
                                        </v-alert>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="primary" type="submit" :disabled="vDisabled"
                                   @click.stop.prevent="doSend">
                            
                                Senden
                            </v-btn>
                            <v-btn color="primary" type="button" 
                                   @click.stop.prevent="doCancel">
                            
                                Abbrechen
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>

        <CaptchaFailDialog :isVisible="showDialogCaptchaFailure" />

        <!-- Absolut positioniert, daher keine row reservieren -->
        <v-snackbar v-model="snackbar" color="success" :timeout="timeout" 
                    absolute top text>

            {{  decoder(snackbarMsg) }}
        </v-snackbar>

    </v-container>
</template>

<script>
    import CaptchaFailDialog from '@/components/CaptchaFailDialog.vue'
    export default {
        name: 'Kontakt',

        components: {
            CaptchaFailDialog,
        },

        data() {
            return {
                kontakt: {
                    vIK: null,
                    vEmail: null,
                    vSelectedKontaktArt: null,
                    vSubject: '',
                    vMessage: '',
                    vTel: ''
                },

                vKontaktArt: [{ text: 'fachlich', value: 0 },
                    { text: 'technisch', value: 1 }],

                showDialog: false,
                showDialogCaptchaFailure: false,
                IKRules: [
                    v => !!v || this.decoder('Ein IK ist erforderlich!'),
                    v => (!isNaN(parseInt(v)) && v >= 300000000 && v <= 309999999) || this.decoder('Geben Sie bitte eine g&#xFC;ltige IK ein!'),
                ],
                emailRules: [
                    v => !!v || this.decoder('Geben Sie bitte eine g&#xFC;ltige Email-Adresse ein'),
                    v => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(v) || this.decoder('Geben Sie bitte eine g&#xFC;ltige Email-Adresse ein')
                ],
                subjectRules: [
                    v => !!v || 'Ein Text ist erforderlich!',
                    v => (v && v.length >= 3) || 'Der Betreff-Text muss mindestens 3 Zeichen lang sein!',
                ],
                feedbackRules: [
                    v => !!v || 'Ein Text ist erforderlich!',
                    v => (v && v.length >= 10) || 'Der Nachricht-Text muss mindestens 10 Zeichen lang sein!',
                ],
                snackbar: false,
                snackbarMsg: 'Vielen Dank f&#xFC;r Ihre Nachricht!\n Sie erhalten eine Kopie Ihrer Nachricht an Ihre E-Mail-Adresse!',
                timeout: 10000,
                errorMessage: ''
            }
        },

        computed: {

            vDisabled() {
                return (this.kontakt.vMessage.length < 10 || this.kontakt.vSubject.length < 3 && this.kontakt.vSelectedKontaktArt == null)
            },

            leistungserbringer() {
                return this.$store.state.currentUser?.leistungserbringer;
            },

            alignment() {

                // Wenn angemeldet, d.h. ohne border ...
                if (this.leistungserbringer) {
                    return 'justify-start align-self-start';
                }

                return 'justify-center align-self-center';
            }
        },

        activated() {
            this.initialize();
        },

        mounted() {
            this.initialize();
        },

        beforeRouteLeave(to, from, next) {
            if (this.snackbar) {
                setTimeout(function () {
                    next();
                }, this.timeout);
            }
            else {
                next();
            }
        },

        methods: {
            initialize() {
                this.kontakt.vIK = '';
                this.kontakt.vSubject = '';
                this.kontakt.vEmail = '';
                this.kontakt.vTel = '';
                this.kontakt.vSelectedKontaktArt = null;
                this.kontakt.vMessage = '';
                this.errorMessage = '';
                this.$refs.form.resetValidation();
            },

            doCancel() {
                this.initialize();

                let route = this.leistungserbringer ? '/home' : '/login';
                this.$router.push(route);
            },

            async doSend() {
                this.$refs.form.validate();
                this.errorMessage = '';

                //await this.verifyCaptcha();
                await this.$recaptchaLoaded();
                const token = await this.$recaptcha('kontakt');

                let currentUser = this.$store.state.currentUser;
                let ik = this.kontakt.vIK != null && this.kontakt.vIK !== "" ? this.kontakt.vIK : currentUser?.leistungserbringer;
                let email = this.kontakt.vEmail != null && this.kontakt.vEmail !== "" ? this.kontakt.vEmail : currentUser?.email;
                let benutzer = currentUser?.benutzer;

                if (!this.vDisabled) {
                    this.$http.post('kontakt/send', {
                        Token: token,
                        Leistungserbringer: parseInt(ik),
                        Benutzer: benutzer,
                        Email: email,
                        Tel: this.kontakt.vTel,
                        FrageArt: this.kontakt.vSelectedKontaktArt,
                        Betreff: this.kontakt.vSubject,
                        Inhalt: this.kontakt.vMessage
                    }).then((response) => {
                        if (response.status === 200) {
                            if (response.data[0] === 0) {
                                this.initialize();
                                this.snackbar = true;
                                this.$router.push('/');
                            }
                            else
                                console.log(response.data[0]);
                        }
                    }).catch(error => {
                        if (error.response) {
                            this.errorMessage = error.response.data.error;
                            console.log(error.response.data.error); // TODO dialog
                        }
                    });
                }
            },

            async verifyCaptcha() {
                try {
                    // get captcha token from Google
                    await this.$recaptchaLoaded();
                    const token = await this.$recaptcha('kontakt');

                    // verify by API
                    const rsp = await this.$http.get("auth/verifycaptcha", { params: { token: token } });
                    if (rsp.status !== 200)
                        throw new Error('Ung�ltiges Captcha');
                }
                catch (err) {
                    this.showDialogCaptchaFailure = true;
                }
            },

            decoder(str) {
                var textArea = document.createElement('textarea');
                textArea.innerHTML = str;
                return textArea.value;
            }

        }

    }
</script>